<template>
  <page>
    <page-head>
      <app-button
          property="success"
          icon="icon-add"
          @click="createModal"
          v-if="can(permission + '.create')"
      >
        {{ translate('button.Add') }}
      </app-button>
    </page-head>

    <page-body>
      <data-grid
          :data-source="hiddenmessages"
          :columns="columns"
          :action-column-width="120"
          :action-column-text="translate(translateKey + '.Label.Action')"
      >
        <div slot="actionSlot" slot-scope="list">
          <app-button
              v-if="can(permission + '.update')"
              @click="createModal(list.row.form)"
              :sm="true"
              :title="translate('button.Info')"
              icon="icon-info"
              property="success"
          />
          <app-button
              v-if="can(permission + '.delete')"
              @click="remove(list.row.id)"
              :sm="true"
              :title="translate('button.Delete')"
              icon="icon-trash"
              property="danger"
          />
        </div>
      </data-grid>
    </page-body>

    <modal :id="modalId" size="sm">
      <modal-head>
        <modal-title>{{ currentPage.title }}</modal-title>
      </modal-head>
      <modal-body v-if="modelShow">
        <form @submit.prevent="save">
          <grid>
            <form-group :label="'İstifadəçinin adı'" name="fullname">
              <form-text v-model="form.fullname" @input="updateFullname"/>
            </form-group>



            <form-group :label="'Oyun adı'" name="game_id">
              <select v-model="form.game_id">
                <option value="" disabled selected>Oyun seçin</option>
                <option v-for="game in games" :key="game.id" :value="game.id">
                  {{ game.name }}
                </option>
              </select>
            </form-group>
            <form-group :label="'Oyunda bəyənmədim'" name="message">
              <form-text v-model="form.message"/>
            </form-group>

            <form-group :label="'Oyuna əlavələrim'" name="question">
              <form-text v-model="form.question"/>
            </form-group>

            <form-group :label="'Əlaqə nömrəsi'" name="number">
              <form-number v-model="form.number"/>
            </form-group>

            <form-group :label="'Aktyorun reytinqi'" name="actor_rate">
              <form-number
                  v-model="form.actor_rate"
                  :min="0"
                  :max="5"
                  :step="0.5"
              />
            </form-group>

            <form-group :label="'Resepşn reytinqi'" name="reception_rate">
              <form-number
                  v-model="form.reception_rate"
                  :min="0"
                  :max="5"
                  :step="0.5"
              />
            </form-group>

            <form-group :label="'Səbəb'" :name="'reason'">
              <form-text v-model="form.reason"/>
            </form-group>

            <form-group :label="'Tarix və Saat'" :name="'created_at'">
              <input type="datetime-local" v-model="form.created_at" @input="formatCreatedAt" class="form-control">
            </form-group>



            <div class="text-center mt-4">
              <app-button
                  property="success"
                  type="submit"
              >
                {{ translate('button.Save') }}
              </app-button>
            </div>
          </grid>
        </form>
      </modal-body>
    </modal>
  </page>
</template>

<script>
import {mapActions, mapState} from 'vuex';

const modalId = 'createModal';
const translateKey = 'crm.HiddenMessage';

export default {
  name: "MessageIndex",
  data() {
    return {
      translateKey,
      modalId,
      modelShow: false,
      columns: [
        {
          caption: translateKey + '.Label.Name',
          dataField: 'fullname',
          show: true
        },
        {
          caption: translateKey + '.Label.GameName',
          dataField: 'game.name',
          show: true
        },
        {
          caption: translateKey + '.Label.Phone',
          dataField: 'number',
          show: true
        },
        {
          caption: translateKey + '.Label.ActorRate',
          dataField: 'actor_rate',
          show: true
        },
        {
          caption: translateKey + '.Label.ReceptionRate',
          dataField: 'reception_rate',
          show: true
        },
        {
          caption: translateKey + '.Label.Created',
          dataField: 'created_at',
          show: true
        },
      ],
      form: {
        id: null,
        game_id: null,
        fullname: '',
        message: '',
        reason: '',
        question: '',
        number: '',
        actor_rate: 0,
        reception_rate: 0,
        created_at: new Date().toISOString()
      }
    }
  },
  computed: {
    ...mapState('HiddenMessageStore', ['hiddenmessages']),
    ...mapState('GameStore', ['games']),

    permission() {
      return this.currentPage.permission;
    }
  },
  methods: {
    updateFullname() {
      if (!this.form.fullname.startsWith("C.C ")) {
        this.form.fullname = "C.C " + this.form.fullname;
      }
    },

    formatCreatedAt() {
      if (!this.form.created_at) return;
      const date = new Date(this.form.created_at);
      this.form.created_at = this.formatDate(date);
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    ...mapActions('HiddenMessageStore', [
      'getHiddenmessages',
      'setHiddenmessage',
      'actionHiddenmessage',
      'deleteHiddenmessage'
    ]),
    ...mapActions('GameStore', ['getSelectGames']),

    formCreate(item = {}) {
      this.form = {
        id: item.id || null,
        game_id: item.game?.id || null,
        fullname: item.fullname || '',
        message: item.message || '',
        reason: item.reason || '',
        question: item.question || '',
        number: item.number || '',
        actor_rate: item.actor_rate || 0,
        reception_rate: item.reception_rate || 0,
        created_at: item.created_at || new Date().toISOString()
      };
      this.modelShow = true;
    },

    createModal(item = {}) {
      this.modal(this.modalId);
      this.modelShow = false;
      this.resetError();
      this.formCreate(item);
    },

    remove(id) {
      this.alert().then(() => {
        this.deleteHiddenmessage(id)
            .then(() => this.getHiddenmessages())
            .catch(error => {
              console.error('Delete error:', error);
              this.$notify.error(this.translate('message.Error'));
            });
      });
    },

    save() {
      try {
        const formData = {
          id: this.form.id,
          game_id: this.form.game_id,
          fullname: this.form.fullname,
          message: this.form.message,
          reason: this.form.reason,
          question: this.form.question,
          number: this.form.number,
          actor_rate: this.form.actor_rate,
          reception_rate: this.form.reception_rate,
          created_at: this.form.created_at
        };

        this.setHiddenmessage(formData)
            .then(() => {
              this.modal(this.modalId);
              this.getHiddenmessages();
              this.$notify.success(this.translate('message.Success'));
            })
            .catch(error => {
              console.error('Save error:', error);
              this.$notify.error(this.translate('message.Error'));
            });
      } catch (error) {
        console.error('Save method error:', error);
        this.$notify.error(this.translate('message.Error'));
      }
    }
    },

  created() {
    console.log(this.games); // games massivini yoxlayın
    this.getHiddenmessages();
    this.getSelectGames();
  }
}
</script>